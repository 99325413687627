<template>
    <note-view-header
        :noteId="id"
        :name="name"
        :engineType="engineType"
        :renderType="renderType"
        :defaultRendering="defaultRendering"
        :shared="true"
    />
    <note-view
        :id="id"
        :name="name"
        :content="content"
        :renderType="renderType"
        :anchor="anchor"
    />
</template>

<script>
    import { defineComponent } from 'vue';
    import NoteView from "@/components/vditor-notes/NoteView";
    import {apiResponseErrorHandle} from "@/composables/errorHandler";
    import useNotes from "@/composables/useNotes";
    import useNoteConverter from "@/composables/useNoteConverter";
    import {MARKDOWN_ENGINE_VDITOR} from "@/constants/note";
    import NoteViewHeader from "@/components/notes/NoteViewHeader.vue";

    export default defineComponent({
        name: "Show",
        components: {
            NoteView,
            NoteViewHeader
        },
        setup() {
            const {getSharedNote} = useNotes();
            const {convertSharedImages} = useNoteConverter();

            return {
                getSharedNote,
                convertSharedImages,
                engineType: MARKDOWN_ENGINE_VDITOR
            }
        },
        data() {
            return {
                id: null,
                name: "",
                content: "",
                defaultRendering: 1,
                renderType: 1,
                renderingDialogVisible: false,
                anchor: null
            }
        },
        created() {
            this.id = parseInt(this.$route.params.noteId);
            this.anchor = this.$route.hash;
        },
        mounted() {
            this.get(this.id);
        },
        methods: {
            get(noteId) {
                const vm = this;
                const sid = this.$route.query.sid;
                const token = this.$route.query.token;
                this.getSharedNote(noteId, {
                    sid: sid,
                    token: token
                }).then(function(response) {
                    vm.$data.name = response.data.note.name;
                    vm.$data.defaultRendering = response.data.note.defaultRendering;
                    vm.renderType = vm.$data.defaultRendering;
                    vm.$data.content = vm.convertSharedImages(response.data.note.content, sid, token);
                }).catch(apiResponseErrorHandle('链接已失效！'))
            }
        }

    })
</script>

<style scoped lang="less">
    html,
    body {
        margin: 0;
        height: 100%;
        font-family: "Helvetica Neue", Arial, sans-serif;
        color: #333;
    }
</style>